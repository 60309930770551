import "./player.css";
import axios from "axios";
import { textTracks } from "./tracks";
import { MediaPlayer, MediaProvider, Track } from "@vidstack/react";
import {
  DefaultVideoLayout,
  defaultLayoutIcons,
} from "@vidstack/react/player/layouts/default";
import { useState, useEffect } from "react";

export function Player() {
  const proxyUrl = "https://m3u8proxy-ivory.vercel.app/m3u8-proxy?url=";
  const episodes = {
    "ep-1": 8384,
    "ep-2": 8385,
    "ep-3": 8386,
    "ep-4": 8387,
    "ep-5": 8388,
    "ep-6": 8389,
    "ep-7": 8390,
    "ep-8": 8391,
    "ep-9": 8392,
    "ep-10": 8393,
  };

  const [episodeNumber, setEpisodeNumber] = useState("");
  const [episodeUrl, setEpisodeUrl] = useState(null);
  const [episodeTracks, setEpisodeTracks] = useState(null);

  const handleInputChange = (e) => {
    setEpisodeNumber(e.target.value);
  };

  const fetchUrl = async (id) => {
    try {
      const response = await axios.post(
        "https://luffy-server-production.up.railway.app/watch",
        {
          episodeId: id,
        }
      );
      return response.data.result.sources.pop().url;
      // const response = await axios.get(
      //   `https://vidsrc.cc/api/episodes/1370882/servers?id=tt4052886&type=tv&season=3&episode=${id}&v=THVjaWZlcl8yMDE2&isMobile=false&vrf=pK2OefFJhepI`
      // );
      // const hash = response.data.data[2].hash;
      // const resp = await axios.get(`https://vidsrc.cc/api/source/${hash}`);
      // return resp.data.data.source;
    } catch (error) {
      console.error("Error fetching episode URL:", error);
      return null;
    }
  };

  useEffect(() => {
    const loadEpisodeData = async () => {
      const episodeId = episodes[`ep-${episodeNumber}`];
      // const episodeId = episodeNumber;
      if (episodeId) {
        const url = await fetchUrl(episodeId);
        setEpisodeUrl(url);
        setEpisodeTracks(textTracks[`ep-${episodeNumber}`] || null);
      } else {
        setEpisodeUrl(null);
        setEpisodeTracks(null);
      }
    };

    if (episodeNumber) {
      loadEpisodeData();
    }
  }, [episodeNumber]);

  return (
    <div className="player-container">
      <input
        type="number"
        placeholder="Enter episode number"
        value={episodeNumber}
        onChange={handleInputChange}
      />
      {episodeUrl ? (
        <MediaPlayer
          src={proxyUrl+episodeUrl}
          viewType="video"
          streamType="on-demand"
          logLevel="warn"
          crossOrigin
          playsInline
        >
          <MediaProvider>
            {episodeTracks ? (
              <Track {...episodeTracks} />
            ) : (
              <p>No subtitles available for this episode.</p>
            )}
          </MediaProvider>
          <DefaultVideoLayout
            icons={defaultLayoutIcons}
          />
        </MediaPlayer>
      ) : (
        <p>Please enter a valid episode number.</p>
      )}
    </div>
  );
}
