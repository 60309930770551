export const textTracks = {
  "ep-1": {
    src: "https://cc.2cdns.com/0b/a3/0ba3dedfc43103bcb487bc86c6875f4f/eng-2.vtt", 
    label: "English",
    language: "en-US",
    kind: "subtitles",
    type: "vtt",
    default: true,
  },
  "ep-2": {
    src: "https://cc.2cdns.com/de/fb/defbb72c41d9b8edc2d97308de0e2246/eng-3.vtt",
    label: "English",
    language: "en-US",
    kind: "subtitles",
    type: "vtt",
    default: true,
  },
  "ep-3": {
    src: "https://cc.2cdns.com/21/62/2162fbf390077fe9d0c762a571a52186/eng-2.vtt", 
    label: "English",
    language: "en-US",
    kind: "subtitles",
    type: "vtt",
    default: true,
  },
  "ep-4": {
    src: "https://cc.2cdns.com/66/30/663040b108a51fa4cf69176a0d74dabe/eng-2.vtt",
    label: "English",
    language: "en-US",
    kind: "subtitles",
    type: "vtt",
    default: true,
  },
  "ep-5": {
    src: "https://cc.2cdns.com/e0/d4/e0d441b7f897597505b8d56d02292fa0/eng-2.vtt", 
    label: "English",
    language: "en-US",
    kind: "subtitles",
    type: "vtt",
    default: true,
  },
  "ep-6": {
    src: "https://cc.2cdns.com/89/9d/899d482236d446583e8bcf577ffd38c5/eng-2.vtt",
    label: "English",
    language: "en-US",
    kind: "subtitles",
    type: "vtt",
    default: true,
  },
  "ep-7": {
    src: "https://cc.2cdns.com/ef/0b/ef0b97ef9b991834cc007485af623db1/eng-2.vtt", 
    label: "English",
    language: "en-US",
    kind: "subtitles",
    type: "vtt",
    default: true,
  },
  "ep-8": {
    src: "https://cc.2cdns.com/a8/3c/a83c41727addd3f1ca8cd960f07d29ef/eng-2.vtt", 
    label: "English",
    language: "en-US",
    kind: "subtitles",
    type: "vtt",
    default: true,
  },
  "ep-9": {
    src: "https://cc.2cdns.com/b1/73/b173e5969c3fcdd3f3a510038a2afe44/eng-2.vtt", 
    label: "English",
    language: "en-US",
    kind: "subtitles",
    type: "vtt",
    default: true,
  },
  "ep-10": {
    src: "https://cc.2cdns.com/c6/f4/c6f4647cce391de18e8f0e61b58b0b3f/eng-2.vtt", 
    label: "English",
    language: "en-US",
    kind: "subtitles",
    type: "vtt",
    default: true,
  },
};
